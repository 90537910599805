import { Component, OnInit } from '@angular/core';
import { ChatService } from './chat.service';
import { UserService } from '../../admin/user.service';
import { EnviarMensaje } from 'src/app/models/EnviarMensaje';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  conversaciones: any[] = [];
  titleChat: string = "Nueva Conversación";
  mensajes: any[] = [];
  chatId: string;
  userId: string;
  ultimoMensaje: string;
  archivoSeleccionado: File | null = null;
  userData: any;

  constructor(private chatService: ChatService, private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getUserId().subscribe(id => {
        this.userId = id;
        console.log("uid: "+this.userId);
    });

    this.userService.getUserData().subscribe(data => {
      this.userData = data;
    });

    this.chatService.getConversaciones().subscribe(data => {
      this.conversaciones = data;
      console.log(this.conversaciones);
    });
  }

  onConversationItemClicked(key: string) {
    console.log('Item de conversación clickeado. KEY: '+key);
    this.chatService.getConversaciones().subscribe(data => {
      this.conversaciones = data;
    });
    this.conversationSelected(key);
  }

  conversationSelected(conversationKey: string){
    const conversation = this.conversaciones.find(conv => conv.key === conversationKey);

    this.chatId = conversationKey;

    if (conversation && conversation.mensajes) {
      this.mensajes = Object.values(conversation.mensajes);
      this.mensajes.sort((a, b) => {
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
      });
      console.log(this.mensajes)
      this.titleChat = conversation.title;
    } else {
      this.mensajes = [];
    }
  }

  crearNuevoChat(){
    let user = new User(
      this.userData.name,
      this.userId
    )

    this.chatService.crearNuevoChat(user).subscribe((data) => {
      if(data){
        this.onConversationItemClicked(data.toString());
      }
    },
   (error) => {
      console.log(error.error.description);
   });
  }

  getFormattedTimestamp(): string {
    const date = new Date();

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

    const microseconds = milliseconds + '000'; // Simulando microsegundos

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${microseconds}`;
  }

  onFileSelected(event: any) {
    this.archivoSeleccionado = event.target.files[0];
  }

  sendMessage() {

    let mensaje = new EnviarMensaje(
      this.ultimoMensaje,
      this.userId,
      this.chatId
    );



    if (this.archivoSeleccionado) {
      this.mensajes.push({
        text: this.ultimoMensaje,
        timestamp: this.getFormattedTimestamp(),
        imageUrl: this.archivoSeleccionado.name+'.'+this.archivoSeleccionado.type,
        role: 'user'
      })
      console.log(this.mensajes)
    }else{
      this.mensajes.push({
        text: this.ultimoMensaje,
        timestamp: this.getFormattedTimestamp(),
        imageUrl: null,
        role: 'user'
      })
    }

    this.ultimoMensaje = null;

    this.chatService.enviarMensaje(mensaje).subscribe(
     (data) => {
        this.mensajes.push({
          text: data,
          timestamp: this.getFormattedTimestamp(),
          imageUrl: null,
          role: 'model'
        })
     },
     (error) => {
        console.log(error.error.description);
    });

    console.log('Mensaje enviado:', this.ultimoMensaje);
    this.archivoSeleccionado = null;
  }

}
