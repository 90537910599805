import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btnwhatsapp',
  templateUrl: './btnwhatsapp.component.html',
  styleUrls: ['./btnwhatsapp.component.css']
})
export class BtnwhatsappComponent implements OnInit {

  stylebtn: any;
  styleback: any;
  flagBtn: boolean = true;
  headbar: any = null;
  bodychat: any = null;
  footerchat: any = null;
  btnSendMsg: any = null;
  containerAll: any = null;
  inputMsg: any = null;
  textMsg: string = null;
  isSafari: boolean;
  backgroundImageURL: string;

  constructor() { }

  ngOnInit(): void {

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.backgroundImageURL = this.isSafari ? '../../../../assets/img/wallpaperwsp.png' : '../../../../assets/img/wallpaperwsp.webp';
    if(this.flagBtn == false){
      this.stylebtn = 'roundedBtn';
      this.styleback = 'nontextClass';
      this.headbar = null;
      this.bodychat = null;
      this.footerchat = null;
      this.btnSendMsg = null;
      this.containerAll = null;
      this.inputMsg = null;
    }else{
      this.styleback = 'textareaClass';
      this.stylebtn = 'sendBtn';
      this.headbar = 'headerActive';
      this.bodychat = 'bodyActivate';
      this.footerchat = 'footerActivate';
      this.btnSendMsg = 'sendMsg';
      this.containerAll = 'relativeContainer';
      this.inputMsg = 'designInput';
    } 

  }

  switchButton(){
    this.flagBtn = !this.flagBtn;

    if(this.flagBtn == false){
      this.stylebtn = 'roundedBtn';
      this.styleback = 'nontextClass';
      this.headbar = null;
      this.bodychat = null;
      this.footerchat = null;
      this.btnSendMsg = null;
      this.containerAll = null;
      this.inputMsg = null;
    }else{
      this.styleback = 'textareaClass';
      this.stylebtn = 'sendBtn';
      this.headbar = 'headerActive';
      this.bodychat = 'bodyActivate';
      this.footerchat = 'footerActivate';
      this.btnSendMsg = 'sendMsg';
      this.containerAll = 'relativeContainer';
      this.inputMsg = 'designInput';
    } 
  }

  btnFunctionSend(){

 
    //console.log(this.textMsg);

    if(this.textMsg != null){
       let url = 'https://api.whatsapp.com/send?phone=573142081126&text=';
       
       let textToUri = this.urlencode(this.textMsg);

      // console.log(textToUri);

       let totalUrl = url+textToUri;

       window.open(totalUrl, '_blank');


    }else{

       window.open('https://api.whatsapp.com/send?phone=573142081126', '_blank');

    }

  }

  urlencode(str) {
    str = (str + '').toString();
    return encodeURIComponent(str)
      .replace('!', '%21')
      .replace('\'', '%27')
      .replace('(', '%28')
      .replace(')', '%29')
      .replace('*', '%2A')
      .replace('%20', '+');
  }

}  
