import { Component, OnInit } from "@angular/core";


@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  isSafari: boolean;
  backgroundImageURL: string;
  constructor() {}

  ngOnInit(): void {

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.backgroundImageURL = this.isSafari ? '../../../assets/img/bg1.png' : '../../../assets/img/bg1.webp';
  }
}
