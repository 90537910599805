export class User{
  userName: string;
  userId: string;

  constructor(
    userName: string,
    userId: string
  ){
    this.userName = userName;
    this.userId = userId;
  }
}
