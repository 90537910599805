import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
//import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import { ContactanosComponent } from './views/contactanos/contactanos.component';
//import { DigitalComponent } from './views/digital/digital.component';
import { LitografiaComponent } from './views/litografia/litografia.component';
import { TresdeComponent } from './views/tresde/tresde.component';
//import { PlotterComponent } from './views/plotter/plotter.component';
//import { EstampadoComponent } from './views/estampado/estampado.component';
//import { DisenioComponent } from './views/disenio/disenio.component';
import { MarketingComponent } from './views/marketing/marketing.component';
//import { SocialmarkComponent } from './views/socialmark/socialmark.component';
import { WebpagesComponent } from './views/webpages/webpages.component';
//import { AppmovileComponent } from './views/appmovile/appmovile.component';
//import { AppdeskComponent } from './views/appdesk/appdesk.component';
import { DigitalComponent } from './views/digital/digital.component';
import { BtnwhatsappComponent } from './components/buttons/btnwhatsapp/btnwhatsapp.component';
import { CotizarwebComponent } from './components/cotizadores/cotizarweb/cotizarweb.component';
import { CortepapelComponent } from './views/cortepapel/cortepapel.component';
import { CutpaperComponent } from './components/utilidades/cutpaper/cutpaper.component';
import { UtilDropdownComponent } from './components/dropdowns/util-dropdown/util-dropdown.component';
import { CotizapendonComponent } from './components/cotizadores/cotizapendon/cotizapendon.component';
import { CotizafacilComponent } from './views/cotizafacil/cotizafacil.component';
import { AndhesivosComponent } from './components/cotizadores/andhesivos/andhesivos.component';
import { TarjetasComponent } from './components/cotizadores/tarjetas/tarjetas.component';
import { SpinnerComponent } from './components/others/spinner/spinner.component';
//import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { EmpresasEventosComponent } from './views/landings/empresas-eventos/empresas-eventos.component';
import { GraciasLeadsComponent } from './views/landings/empresas-eventos/gracias-leads/gracias-leads.component';
import { LandingComponent } from "./layouts/landings/landing.component";
import { ChatComponent } from './views/admin/chat/chat.component';


import { SimplebarAngularModule } from 'simplebar-angular';
import { ConversationItemComponent } from './views/admin/chat/conversation-item/conversation-item.component';
import { MessageItemComponent } from './views/admin/chat/message-item/message-item.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    LandingComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    WebpagesComponent,
    ContactanosComponent,
    DigitalComponent,
    TresdeComponent,
    LitografiaComponent,
    MarketingComponent,
    ProfileComponent,
    BtnwhatsappComponent,
    CotizarwebComponent,
    CortepapelComponent,
    CutpaperComponent,
    UtilDropdownComponent,
    CotizapendonComponent,
    CotizafacilComponent,
    AndhesivosComponent,
    TarjetasComponent,
    SpinnerComponent,
    EmpresasEventosComponent,
    GraciasLeadsComponent,
    ChatComponent,
    ConversationItemComponent,
    MessageItemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    SimplebarAngularModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
