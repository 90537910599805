import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cotizarweb',
  templateUrl: './cotizarweb.component.html',
  styleUrls: ['./cotizarweb.component.css']
})
export class CotizarwebComponent implements OnInit {
  sectionCount: number = 0;
  tipoWeb: any = {};
  diseñoWeb: any = {};
  tamañoWeb: any = {};
  pagosWeb: any = {};
  apiWeb: any = {};
  loginWeb: any = {};
  idiomasWeb: any = {};
  buscadorWeb: any = {};
  seoWeb: any = {};
  estadoWeb: any = {};
  totalPagar: number = 0;
  totalPagar2: any = 0;
  constructor() { }

  ngOnInit(): void {

    this.tipoWeb = {
      seleccionado: null,
      precio: 0
    }
    this.diseñoWeb = {
      seleccionado: null,
      precio: 0
    }
    this.tamañoWeb = {
      seleccionado: null,
      precio: 0
    }
    this.pagosWeb = {
      seleccionado: null,
      precio: 0
    }
    this.apiWeb = {
      seleccionado: null,
      precio: 0
    }
    this.loginWeb = {
      seleccionado: null,
      precio: 0
    }
    this.idiomasWeb = {
      seleccionado: null,
      precio: 0
    }
    this.buscadorWeb = {
      seleccionado: null,
      precio: 0
    }
    this.seoWeb = {
      seleccionado: null,
      precio: 0
    }
    this.estadoWeb = {
      seleccionado: null,
      precio: 0
    }



  }

  nextView(){

    const formatterPeso = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    })

    if(this.sectionCount == 0){
      this.sectionCount +=1;
    }else{
      if(this.sectionCount == 1){
        if(this.tipoWeb.seleccionado != null){
          this.sectionCount +=1;

          this.totalPagar = Math.trunc(this.tipoWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }      
      }else if(this.sectionCount == 2){
        if(this.diseñoWeb.seleccionado != null){
          this.totalPagar = Math.trunc(this.tipoWeb.precio*this.diseñoWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
          this.sectionCount +=1;
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 3){
        if(this.tamañoWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 4){
        if(this.pagosWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc(((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 5){
        if(this.apiWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc((((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio)+this.apiWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 6){
        if(this.loginWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc(((((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio)+this.apiWeb.precio)+this.loginWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 7){
        if(this.idiomasWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc((((((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio)+this.apiWeb.precio)+this.loginWeb.precio)+this.idiomasWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 8){
        if(this.buscadorWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc(((((((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio)+this.apiWeb.precio)+this.loginWeb.precio)+this.idiomasWeb.precio)+this.buscadorWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 9){
        if(this.seoWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc((((((((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio)+this.apiWeb.precio)+this.loginWeb.precio)+this.idiomasWeb.precio)+this.buscadorWeb.precio)+this.seoWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }else if(this.sectionCount == 10){
        if(this.estadoWeb.seleccionado != null){
          this.sectionCount +=1;
          this.totalPagar = Math.trunc(((((((((this.tipoWeb.precio*this.diseñoWeb.precio)*this.tamañoWeb.precio)+this.pagosWeb.precio)+this.apiWeb.precio)+this.loginWeb.precio)+this.idiomasWeb.precio)+this.buscadorWeb.precio)+this.seoWeb.precio)*this.estadoWeb.precio);
          this.totalPagar2 = formatterPeso.format(this.totalPagar);
        }else{
          alert('Debe seleccionar una de las opciones para continuar.');
        }  
      }
    }
    
  }
 
  backView(){
    this.sectionCount -=1;
  }

  radioChangeHandlerSection1(e: any) {
    console.log(e.target.id);

    this.tipoWeb.seleccionado = e.target.id;

    if(e.target.id == 'WebEcommerce'){

      this.tipoWeb.precio = 1000000;

    }else if(e.target.id == 'WebBlog'){

      this.tipoWeb.precio = 800000;

    }else if(e.target.id == 'WebEmpresa'){

      this.tipoWeb.precio = 800000;
      
    }else{

      this.tipoWeb.precio = 1700000;

    }

  }

  radioChangeHandlerSection2(e: any) {
    console.log(e.target.id);

    this.diseñoWeb.seleccionado = e.target.id;

    if(e.target.id == 'designPlantilla'){

      this.diseñoWeb.precio = 1.2;

    }else if(e.target.id == 'designMedida'){

      this.diseñoWeb.precio = 1.5;

    }else if(e.target.id == 'noDesign'){

      this.diseñoWeb.precio = 1;
      
    }else{

      this.diseñoWeb.precio = 1;

    }
  }

  radioChangeHandlerSection3(e: any) {
    console.log(e.target.id);

    this.tamañoWeb.seleccionado = e.target.id;

    if(e.target.id == 'sizeSmall'){

      this.tamañoWeb.precio = 1;

    }else if(e.target.id == 'sizeMedium'){

      this.tamañoWeb.precio = 1.3;

    }else if(e.target.id == 'sizeBig'){

      this.tamañoWeb.precio = 1.6;
      
    }else{

      this.tamañoWeb.precio = 1;

    }
  }

  radioChangeHandlerSection4(e: any) {
    console.log(e.target.id);

    this.pagosWeb.seleccionado = e.target.id;

    if(e.target.id == 'siPagos'){

      if(this.tipoWeb.seleccionado == 'WebEcommerce'){
        this.pagosWeb.precio = 0;
      }else{
        this.pagosWeb.precio = 200000;
      }

    }else if(e.target.id == 'noPagos'){

      this.pagosWeb.precio = 0;

    }else{

      this.pagosWeb.precio = 0;

    }
  }

  radioChangeHandlerSection5(e: any) {
    console.log(e.target.id);

    this.apiWeb.seleccionado = e.target.id;

    if(e.target.id == 'siAPIS'){

      this.apiWeb.precio = 500000;

    }else if(e.target.id == 'noAPIS'){

      this.apiWeb.precio = 0;

    }else{

      this.apiWeb.precio = 0;

    }
  }

  radioChangeHandlerSection6(e: any) {
    console.log(e.target.id);

    this.loginWeb.seleccionado = e.target.id;

    if(e.target.id == 'siLogin'){

      this.loginWeb.precio = 650000;

    }else if(e.target.id == 'noLogin'){

      this.loginWeb.precio = 0;

    }else{

      this.loginWeb.precio = 0;

    }
  }

  radioChangeHandlerSection7(e: any) {
    console.log(e.target.id);

    this.idiomasWeb.seleccionado = e.target.id;

    if(e.target.id == 'siIdioma'){

      this.idiomasWeb.precio = 200000;

    }else if(e.target.id == 'noIdioma'){

      this.idiomasWeb.precio = 0;

    }else{

      this.idiomasWeb.precio = 0;

    }
  }

  radioChangeHandlerSection8(e: any) {
    console.log(e.target.id);

    this.buscadorWeb.seleccionado = e.target.id;

    if(e.target.id == 'siBuscador'){

      this.buscadorWeb.precio = 300000;

    }else if(e.target.id == 'WebBlog'){

      this.buscadorWeb.precio = 0;

    }else{

      this.buscadorWeb.precio = 0;

    }
  }

  radioChangeHandlerSection9(e: any) {
    console.log(e.target.id);

    this.seoWeb.seleccionado = e.target.id;

    if(e.target.id == 'siSEO'){

      this.seoWeb.precio = 250000;

    }else if(e.target.id == 'noSEO'){

      this.seoWeb.precio = 0;

    }else{

      this.seoWeb.precio = 0;

    }
  }

  radioChangeHandlerSection10(e: any) {
    console.log(e.target.id);

    this.estadoWeb.seleccionado = e.target.id;

    if(e.target.id == 'estadoIdea'){

      this.estadoWeb.precio = 1.3;

    }else if(e.target.id == 'estadoBoceto'){

      this.estadoWeb.precio = 1.2;

    }else if(e.target.id == 'estadoDesarrollo'){

      this.estadoWeb.precio = 1.1;
      
    }else{

      this.estadoWeb.precio = 1;

    }
  }

  sendCotizacionWsp(){

       let fecha: Date = new Date();

       let hora = fecha.getHours();

       console.log(hora);

       let mensaje: string;

       if(hora > 11){
         mensaje = "Buenas tardes, me quiero comunicar con ustedes para solicitar una página con las siguientes caracteristicas 1.Tipo: "+this.tipoWeb.seleccionado+", 2.Diseño: "+this.diseñoWeb.seleccionado+", 3.Tamaño: "+this.tamañoWeb.seleccionado+", 4.Pagos: "+this.pagosWeb.seleccionado+", 5.Conexiones API: "+this.apiWeb.seleccionado+", 6.Login: "+this.loginWeb.seleccionado+", 7.Idiomas: "+this.idiomasWeb.seleccionado+", 8.Buscador: "+this.buscadorWeb.seleccionado+", 9.SEO: "+this.seoWeb.seleccionado+", 10.Estado: "+this.estadoWeb.seleccionado+". Todo ello con un valor aprox. de: $"+this.totalPagar+". Gracias, quedo a la espera de su respuesta.";
       }else{
        mensaje = "Buenas días, me quiero comunicar con ustedes para solicitar una página con las siguientes caracteristicas 1.Tipo: "+this.tipoWeb.seleccionado+", 2.Diseño: "+this.diseñoWeb.seleccionado+", 3.Tamaño: "+this.tamañoWeb.seleccionado+", 4.Pagos: "+this.pagosWeb.seleccionado+", 5.Conexiones API: "+this.apiWeb.seleccionado+", 6.Login: "+this.loginWeb.seleccionado+", 7.Idiomas: "+this.idiomasWeb.seleccionado+", 8.Buscador: "+this.buscadorWeb.seleccionado+", 9.SEO: "+this.seoWeb.seleccionado+", 10.Estado: "+this.estadoWeb.seleccionado+". Todo ello con un valor aprox. de: $"+this.totalPagar+". Gracias, quedo a la espera de su respuesta.";
       }

       let url = 'https://api.whatsapp.com/send?phone=573142081126&text=';
       
       let textToUri = this.urlencode(mensaje);

      // console.log(textToUri);

       let totalUrl = url+textToUri;

       window.open(totalUrl, '_blank');

  }

  urlencode(str) {
    str = (str + '').toString();
    return encodeURIComponent(str)
      .replace('!', '%21')
      .replace('\'', '%27')
      .replace('(', '%28')
      .replace(')', '%29')
      .replace('*', '%2A')
      .replace('%20', '+');
  }

}


