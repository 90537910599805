import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['../digital/digital.component.css']
})
export class MarketingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
