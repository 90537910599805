import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnviarMensaje } from '../../../models/EnviarMensaje';
import { User } from 'src/app/models/User';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private _http: HttpClient
  ) { }

  getConversaciones(): Observable<any[]> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const userId = user.uid;
          return this.db.list(`users/${userId}/conversaciones`).snapshotChanges().pipe(
            map(changes =>
              changes.map(c => ({ key: c.key, ...(c.payload.val() as {}) }))
            )
          );
        } else {
          return of([]); // Devuelve un Observable con una lista vacía si no hay usuario autenticado
        }
      })
    );
  }

  crearNuevoChat(user: User){
    return this._http.post(environment.API_URL_CREAR_NUEVO_CHAT, user);
  }


  enviarMensaje(mensaje: EnviarMensaje){
    return this._http.post(environment.API_URL_ENVIAR_MENSAJE, mensaje);
  }
}



