export class EnviarMensaje{
    prompt: string;
    userId: string;
    chatId: string;

    constructor(
      prompt: string,
      userId: string,
      chatId: string
    ){
      this.prompt = prompt;
      this.userId = userId;
      this.chatId = chatId;
    }
}
