import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";
import { ChatComponent } from "./views/admin/chat/chat.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
//import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { ContactanosComponent } from './views/contactanos/contactanos.component';
import { DigitalComponent } from './views/digital/digital.component';
import { LitografiaComponent } from './views/litografia/litografia.component';
import { TresdeComponent } from './views/tresde/tresde.component';
//import { PlotterComponent } from './views/plotter/plotter.component';
//import { EstampadoComponent } from './views/estampado/estampado.component';
//import { DisenioComponent } from './views/disenio/disenio.component';
import { MarketingComponent } from './views/marketing/marketing.component';
//import { SocialmarkComponent } from './views/socialmark/socialmark.component';
import { WebpagesComponent } from './views/webpages/webpages.component';
//import { AppmovileComponent } from './views/appmovile/appmovile.component';
import { CortepapelComponent } from './views/cortepapel/cortepapel.component';
import { CotizafacilComponent } from './views/cotizafacil/cotizafacil.component';
import { PermissionsGuard } from "./permissions.guard";
import { EmpresasEventosComponent } from "./views/landings/empresas-eventos/empresas-eventos.component";
import { GraciasLeadsComponent } from "./views/landings/empresas-eventos/gracias-leads/gracias-leads.component";
import { LandingComponent } from "./layouts/landings/landing.component";


const routes: Routes = [
  { path: 'profile', component: ProfileComponent, canActivate: [PermissionsGuard] },
  { path: '', component: IndexComponent },
  { path: 'contacto', component: ContactanosComponent },
  { path: 'digital', component: DigitalComponent },
  { path: 'litografia', component: LitografiaComponent },
  { path: 'tresde', component: TresdeComponent },
  { path: 'cortepapel', component: CortepapelComponent },
  { path: 'marketing', component: MarketingComponent },
  { path: 'webpages', component: WebpagesComponent },
  { path: 'cotizafacil/:id', component: CotizafacilComponent },
  {
    path: 'admin', canActivate: [PermissionsGuard],
    component: AdminComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'tables', component: TablesComponent },
      { path: 'chat', component: ChatComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent, canActivate: [PermissionsGuard] },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
    ],
  },
  {
    path: 'landings',
    component: LandingComponent,
    children: [
      { path: 'lndg-digital-print', component: EmpresasEventosComponent },
      { path: 'thks-digital-print', component: GraciasLeadsComponent },
    ],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
