import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from "@angular/router";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent {
  nameUser: string = "";
  emailUser: string = "";
  passUser: string = "";
  errorMessage: string = "";
  flagError: boolean = false; // Agregado para manejar errores de registro
  flagLoading: boolean = false;
  statusPolicy: boolean = false;
  classEye: string = "fas fa-eye";
  typePass: string = "password";
  flagSuccess: boolean = false; // Agregado para mensaje de éxito
  flagAlert: boolean = false; // Agregado para alertas
  messageAlert: string = ''; // Agregado para mensaje de alerta

  constructor(private auth: AngularFireAuth, private db: AngularFireDatabase, private router: Router) {}

  registerWithEmailPassword() {
    this.flagLoading = true;
    this.flagError = false;

    this.auth
      .createUserWithEmailAndPassword(this.emailUser, this.passUser)
      .then((userCredential) => {
        // Registro exitoso, puedes redirigir o realizar otras acciones necesarias
        console.log("Usuario registrado correctamente:", userCredential.user);
         // Obtener el UID del usuario registrado
         const uid = userCredential.user?.uid;

         // Guardar datos adicionales en Realtime Database
         if (uid) {
           const userData = {
             name: this.nameUser,
             email: this.emailUser,
             // Otros datos que quieras guardar
           };

           this.db.object(`users/${uid}`).set(userData)
             .then(() => {
               this.flagLoading = false;
               this.flagSuccess = true; // Mostrar mensaje de éxito
               this.router.navigate(['/auth/login']);
             })
             .catch((error) => {
               console.error("Error al guardar datos del usuario:", error);
               this.errorMessage = error.message;
               this.flagError = true; // Mostrar mensaje de error
               this.flagLoading = false;
             });
         }
      })
      .catch((error) => {
        // Manejo de errores
        console.error("Error al registrar usuario:", error);
        this.errorMessage = error.message;
        this.flagError = true; // Mostrar mensaje de error
        this.flagLoading = false;
      });
  }

  togglePasswordVisibility() {
    this.typePass = this.typePass === "password" ? "text" : "password";
    this.classEye = this.typePass === "password" ? "fas fa-eye" : "fas fa-eye-slash";
  }

  onPolicyCheck(checked: boolean) {
    this.statusPolicy = checked;
  }

  registerWithGoogle(){
    console.log("en desarrollo")
  }
}
