import { Component, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';




@Component({
  selector: 'app-cutpaper',
  templateUrl: './cutpaper.component.html',
  styleUrls: ['./cutpaper.component.css']
})
export class CutpaperComponent implements OnInit, AfterViewInit {

  @ViewChild('myCanvas')
  private myCanvas: ElementRef = {} as ElementRef;


  hPliego: any = 0;
  vPliego: any = 0;
  hCorte: any = 0;
  vCorte: any = 0; 
  cantCortes: any = 0;
  orientacion: any = 'Vertical';
  cortes: any = 0;
  sobrante: any = 0;
  totalCortes: any = 0;
  cortesV: any = 0;
  cortesH:any = 0;
  canvas: any;
  context: any;
  flagTamanio: boolean = false;
  dataSize: any;

  costoHoja: any = 0

  widthCanvas: number = 300;
  heightCanvas: number = 450;

  porcAreaUtil: any = 0;

  porcAreaInutil: any = 0;

  cortesPliego: any = 0;
  cortesUtilizables: any = 0;
  pliegos: any = 0;
  noCortes: any = 0;
  noCortesV: any = 0;
  noCortesH: any = 0;
  vTotalHojas:any = 0;

  constructor() { }

  ngOnInit(): void {

    if(window.screen.width < 380){

      this.widthCanvas = 200;
      this.heightCanvas = 350;

    }

    this.dataSize = [
      {
        name: 'Carta',
      },
      {
        name: 'Oficio',
      },
      {
        name: 'Pliego',
      },
      {
        name: '1/2 Pliego',
      },
      {
        name: '1/4 Pliego',
      },
      {
        name: '1/8 Pliego',
      },
      {
        name: 'A0',
      },
      {
        name: 'A1',
      },
      {
        name: 'A2',
      },
      {
        name: 'A3',
      },
      {
        name: 'A4',
      },
      {
        name: 'A5',
      },
      {
        name: 'A6',
      },
      {
        name: 'A7',
      },
      {
        name: 'A8',
      },
      {
        name: 'A9',
      },
      {
        name: 'A10',
      },
      {
        name: 'Láser',
      }

    ]

  }

  ngAfterViewInit(): void {
    this.canvas = document.getElementById("micanvas");
    this.context = this.myCanvas.nativeElement.getContext('2d');
  }
  orientacionV(val:number){

   
    var cortes, totalCortes, sobrante;

    if(this.validacionCampos() == 1){
      this.orientacion = 'Vertical';
      var b = Math.max(this.hPliego, this.vPliego);
      var h = Math.min(this.hPliego, this.vPliego);
      console.log('ancho: ', window.screen.width);
      if(window.screen.width < 470){
        var escala = 250/b;
      }else{
        var escala = 380/b;
      }
     
      var cortesV, cortesH = 0;

      this.clearCanvas();
      if(val == 1){
        this.widthCanvas = h*escala;
        this.heightCanvas = b*escala;
      }else if(val == 2){
        this.widthCanvas = b*escala;
        this.heightCanvas = h*escala;
      }
      
      
      setTimeout(() => {

        if(val == 1){
          cortes = this.acomodarCortes(b,h,"N","V");
        }else if(val == 2){
          cortes = this.acomodarCortes(b,h,"N","H");
        }
        
        totalCortes = cortes.cortesT;

        console.log('cortes: ', cortes);
  
        this.dibujarCuadricula(cortes.cortesB, cortes.cortesH, this.hCorte, this.vCorte, 0, 0, escala, '');
  
    if(val == 1){
      if (cortes.sobranteB >= this.vCorte) {
          sobrante = this.acomodarCortes(cortes.sobranteB, b, "H", "H");
          totalCortes += sobrante.cortesT;
          this.dibujarCuadricula(sobrante.cortesH, sobrante.cortesB, this.vCorte, this.hCorte, cortes.cortesB * this.hCorte * escala, 0, escala, "R");
      } else if (cortes.sobranteH >= this.hCorte) {
          sobrante = this.acomodarCortes(cortes.sobranteH, h, "H", "H");
          totalCortes += sobrante.cortesT;
          this.dibujarCuadricula(sobrante.cortesB, sobrante.cortesH, this.vCorte, this.hCorte, 0, cortes.cortesH * this.vCorte * escala, escala, "R");
      } else {
          sobrante = {
              cortesT: 0,
              cortesB: 0,
              cortesH: 0,
              sobranteB: 0,
              sobranteH: 0,
              areaUtilizada: 0
          };
      }
    }else{
      if (cortes.sobranteB >= this.vCorte) {
        sobrante = this.acomodarCortes(cortes.sobranteB, h, "H", "H");
        totalCortes += sobrante.cortesT;
        this.dibujarCuadricula(sobrante.cortesH, sobrante.cortesB, this.vCorte, this.hCorte, cortes.cortesB * this.hCorte * escala, 0, escala, "R");
    } else if (cortes.sobranteH >= this.hCorte) {
        sobrante = this.acomodarCortes(cortes.sobranteH, b, "H", "H");
        totalCortes += sobrante.cortesT;
        this.dibujarCuadricula(sobrante.cortesB, sobrante.cortesH, this.vCorte, this.hCorte, 0, cortes.cortesH * this.vCorte * escala, escala, "R");
    } else {
        sobrante = {
            cortesT: 0,
            cortesB: 0,
            cortesH: 0,
            sobranteB: 0,
            sobranteH: 0,
            areaUtilizada: 0
        };
    }
    }

      if (this.hCorte < this.vCorte) {
        cortesV = cortes.cortesT;
        cortesH = sobrante.cortesT;
    } else {
        cortesV = sobrante.cortesT;
        cortesH = cortes.cortesT;
    }
    
    
      this.calcularArea(b, h, this.hCorte, this.vCorte, totalCortes);

      if(val == 1){
      this.calcular(b, h, cortesV, cortesH, totalCortes, cortes.cortesT, "V");
      }else{
        this.calcular(b, h, cortesV, cortesH, totalCortes, cortes.cortesT, "H");
      }

      }, 50);

    }

  }
  orientacionMax(){
    if(this.validacionCampos() === 1) {
      this.orientacion = "Maximo";
      var b = Math.max(this.hPliego,this.vPliego);
      var h = Math.min(this.hPliego,this.vPliego);
      var cb = Math.max(this.hCorte,this.vCorte);
      var ch = Math.min(this.hCorte,this.vCorte);
      console.log('ancho: ', window.screen.width);
      if(window.screen.width < 470){
        var escala = 250/b;
      }else{
        var escala = 380/b;
      }
      var a1h = h;
      var a1b = b;
      var a2h, a2b, sumaCortes = 0;
      var corteA1, corteA2;
      var totalCortes;
      var acomodo1: any , acomodo2: any = {
          a1b: "",
          a2b: "",
          a1h: "",
          a2h: "",
          sumaCortes: "" 
      };
      
      this.clearCanvas();
      this.widthCanvas = b*escala;
      this.heightCanvas = h*escala;
      
     
      setTimeout(() => {
        var cortes = this.acomodarCortes(b, h, "H", "M");
      
      totalCortes = cortes.cortesT;
      acomodo1 = {
          a1b: b, 
          a2b: b, 
          a1h: h, 
          a2h: 0, 
          sumaCortes: totalCortes, 
          cortesH1: cortes.cortesH,
          cortesB1: cortes.cortesB,
          cortesT1: cortes.cortesT,
          cortesH2: 0,
          cortesB2: 0,
          cortesT2: 0
      };
      
      for (var x = 0; x <= cortes.cortesH; x++) {
          a2b = b;
          
          a2h = parseFloat(((ch * x) + cortes.sobranteH).toFixed(2));
          a1h = parseFloat((h - a2h).toFixed(2));
          
          corteA1 = this.acomodarCortes(a1b, a1h, "H", "N");
          corteA2 = this.acomodarCortes(a2b, a2h, "V", "N");
          
          sumaCortes = corteA1.cortesT + corteA2.cortesT;
          
          if(sumaCortes > totalCortes) {
              totalCortes = sumaCortes;
              acomodo1 = {
                  a1b: a1b, 
                  a2b: a2b, 
                  a1h: a1h, 
                  a2h: a2h, 
                  sumaCortes: totalCortes, 
                  cortesH1: corteA1.cortesH, 
                  cortesB1: corteA1.cortesB,
                  cortesT1: corteA1.cortesT,
                  cortesH2: corteA2.cortesH,
                  cortesB2: corteA2.cortesB,
                  cortesT2: corteA2.cortesT
              };
          }
          //console.log("x: " + x + " corte A1: " + corteA1.cortesT + " corte A2: " + corteA2.cortesT + " cortes: " + sumaCortes);
      }
      
      //console.log(acomodo1.sumaCortes);
      
      totalCortes = cortes.cortesT;
      var acomodo2: any = {a1b: b, a2b: 0, a1h: h, a2h: h, sumaCortes: totalCortes, cortesH: totalCortes, cortesV: 0};
      
      for (x = 0; x <= cortes.cortesB; x++) {
         var a2h, a1h = h;
          
          a2b = parseFloat(((cb * x) + cortes.sobranteB).toFixed(2));
          a1b = parseFloat((b - a2b).toFixed(2));
          
          corteA1 = this.acomodarCortes(a1b, a1h, "H", "N");
          corteA2 = this.acomodarCortes(a2b, a2h, "V", "N");
          
          sumaCortes = corteA1.cortesT + corteA2.cortesT;
          
          if(sumaCortes > totalCortes) {
              totalCortes = sumaCortes;
              acomodo2 = {
                  a1b: a1b, 
                  a2b: a2b, 
                  a1h: a1h, 
                  a2h: a2h, 
                  sumaCortes: totalCortes, 
                  cortesH1: corteA1.cortesH, 
                  cortesB1: corteA1.cortesB,
                  cortesT1: corteA1.cortesT,
                  cortesH2: corteA2.cortesH,
                  cortesB2: corteA2.cortesB,
                  cortesT2: corteA2.cortesT
              };
          }
          //console.log("x: " + x + " corte A1: " + corteA1.cortesT + " corte A2: " + corteA2.cortesT + " cortes: " + sumaCortes);
      }
      
      //console.log(acomodo2.sumaCortes);
      
      if(acomodo2.sumaCortes > acomodo1.sumaCortes) {
          //Calculando el area
          this.calcularArea(b, h, cb, ch, acomodo2.sumaCortes);
          this.calcular(b, h, acomodo2.cortesT2, acomodo2.cortesT1, parseInt(acomodo2.sumaCortes), acomodo2.sumaCortes, "M");
          //console.log("Corte V: " + acomodo2.sumaCortes);
          //Dibuja 2 areas una al lado de otra
          this.dibujarCuadricula(acomodo2.cortesB1, acomodo2.cortesH1, cb, ch, 0, 0, escala, '');
          this.dibujarCuadricula(acomodo2.cortesB2, acomodo2.cortesH2, ch, cb, acomodo2.cortesB1 * cb * escala, 0, escala, '');
      } else {
          //Calculando el area
          this.calcularArea(b, h, cb, ch, acomodo1.sumaCortes);
          this.calcular(b, h, acomodo1.cortesT2, acomodo1.cortesT1, acomodo1.sumaCortes, parseInt(acomodo1.sumaCortes), "M");
          //console.log("Corte H: " + acomodo1.sumaCortes);
          //Dibuja 2 areas una arriba de otra
          this.dibujarCuadricula(acomodo1.cortesB1, acomodo1.cortesH1, cb, ch, 0, 0, escala,'');
          this.dibujarCuadricula(acomodo1.cortesB2, acomodo1.cortesH2, ch, cb, 0, acomodo1.cortesH1 * ch * escala, escala,'');
      }
      }, 50);
  }
  }
  validacionCampos(){
    var valida = 1;

    if(this.hPliego == 0 || this.vPliego == 0 || this.hCorte == 0 || this.vCorte == 0 || this.cantCortes == 0 ){
      valida = 0;
    }

    return valida;
    
  }
  clearCanvas() {

   this.context.clearRect(0, 0, this.widthCanvas, this.heightCanvas);

  }
  acomodarCortes(base, altura, acomodoCorte, acomodoPliego){

  var cb = 1;
    var ch = 1;
    var b = 1;
    var h = 1;
    
    if(acomodoPliego === "V") {
        b = Math.min(base, altura);
        h = Math.max(base, altura);
    } else if (acomodoPliego === "H") {
    /* Acomodo del pliego en horizontal y para el calculo del maximo
     */
        b = Math.max(base, altura);
        h = Math.min(base, altura);        
    } else {
        b = base;
        h = altura;
    }
    
    if (acomodoCorte === 'H') {
        cb = Math.max(this.hCorte, this.vCorte);
        ch = Math.min(this.hCorte, this.vCorte);       
    } else if(acomodoCorte === 'V') {
        cb = Math.min(this.hCorte, this.vCorte);
        ch = Math.max(this.hCorte, this.vCorte);   
    } else {
        cb = this.hCorte;
        ch = this.vCorte;
    }

    console.log('anchoAdhe', this.hCorte)
        console.log('altoAdhe', this.vCorte)
    
    var cortesT = (Math.trunc(b / cb)) * (Math.trunc(h / ch));
    var cortesB = Math.trunc(b / cb);
    var cortesH = Math.trunc(h / ch);
    var sobranteB = parseFloat((b - (cortesB * cb)).toFixed(2));
    var sobranteH = parseFloat((h - (cortesH * ch)).toFixed(2));
    var areaUtilizada = parseFloat(((cb * ch) * (Math.trunc(b / cb)) * (Math.trunc(h / ch))).toFixed(2));
    
    var cortes = {
        cortesT: cortesT,
        cortesB: cortesB,
        cortesH: cortesH,
        sobranteB: sobranteB,
        sobranteH: sobranteH,
        areaUtilizada: areaUtilizada
    };
    
    return cortes;

  }
  dibujarCuadricula(cortesX, cortesY, width, height, coorX, coorY, escala, color){
    if(color === "R") {
      color = ' #FF9D9D';
    } else {
      color = '#ecdac4';
    }
  
     
     var coorY1 = coorY;
     var coorX1 = coorX;
  
     width = escala * width;
     height = escala * height;
  
     
     for (var x = 1; x <= cortesX; x++) {
      
        coorY = coorY1;
      
        for (var y = 1; y <=cortesY; y++) {
          
          this.context.beginPath();
          this.context.fillStyle = color;
          this.context.rect(coorX, coorY, width, height);
          this.context.fill();
          this.context.lineWidth = 1;
          this.context.strokeStyle = '#fff';                      
                     
          this.context.stroke();
          
          coorY = (height * y) + coorY1;
       }
      
        coorX = (width * x) + coorX1;
     }
  }
  calcularArea(ancho_papel, largo_papel, ancho_corte, largo_corte, cortes_en_pliego) {
    var area_papel: number = ancho_papel * largo_papel;
    var area_corte: number = ancho_corte * largo_corte;

    var area_utilizada_cortes: number = cortes_en_pliego * area_corte;

    var porcentaje_area_utilizada = ((area_utilizada_cortes * 100) / area_papel).toFixed(2);
    var aux: number = ((area_utilizada_cortes * 100) / area_papel);
    var porcentaje_area_inutilizada = (100 - aux).toFixed(2);

    this.porcAreaUtil = porcentaje_area_utilizada;
    this.porcAreaInutil = porcentaje_area_inutilizada;
  }
  calcular(b, h, cortesV, cortesH, totalCortes, utilizables, orientacion) {
  var cortesDeseados = this.cantCortes;
  var pliegosP = 1;
  var pliegos = 0;
  
  if (orientacion === "H") {
      pliegos = Math.ceil(cortesDeseados / utilizables);
  } else if (orientacion === "V") {
      pliegos = Math.ceil(cortesDeseados / utilizables);
  } else {
      //Calculando el numero de pliegos necesarios
      pliegos = Math.ceil(cortesDeseados / totalCortes);
  }

  if(pliegos !== 0 && !isNaN(pliegos)) {
      pliegosP = pliegos;
  } else if (isNaN(pliegos)) {
      pliegos = 0;
  }
  
 
  
  //Calculando el numero total de cortes en todos los pliegos
  var no_total_cortes = totalCortes * pliegos;
  
  //Imprimiendo resultados
  this.imprimirResultados(totalCortes, pliegos, no_total_cortes, cortesH, cortesV, utilizables);
  }
  imprimirResultados(cortes_pliego, pliegos, cortes, cortesH, cortesV, utilizables) {
  this.cortesPliego = Math.trunc(cortes_pliego);
  this.cortesUtilizables = Math.trunc(utilizables);
  this.pliegos = Math.trunc(pliegos);
  this.noCortes = Math.trunc(cortes);
  this.noCortesV = Math.trunc(cortesV);
  this.noCortesH = Math.trunc(cortesH);

  this.vTotalHojas = this.costoHoja*pliegos;
  }
  reset(){

    this.widthCanvas = 300;
    this.heightCanvas = 450;

    this.clearCanvas();

    this.hPliego= 0;
    this.vPliego = 0;
    this.hCorte = 0;
    this.vCorte = 0; 
    this.cantCortes = 0;

    this.cortesPliego = 0;
    this.noCortesH = 0;
    this.noCortesV = 0;
    this.pliegos = 0;
    this.noCortes = 0;
    this.porcAreaUtil = 0;
    this.porcAreaInutil = 0;

  }
  onChange(value:any){
   console.log(value);

      var b;
      var h;
      var escala;

   switch (value) {
    case '0':
      this.hPliego = 0;
      this.vPliego = 0;
      if(window.screen.width < 380){

        this.widthCanvas = 200;
        this.heightCanvas = 350;
  
      }else{
        this.widthCanvas = 300;
        this.heightCanvas = 450;
      }
      
      this.costoHoja = 0;
      break;
    case '1':
      this.hPliego = 21.5;
      this.vPliego = 28;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 22*escala;
      this.heightCanvas = 28*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '2':
      this.hPliego = 21.5;
      this.vPliego = 33;

      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 22*escala;
      this.heightCanvas = 34*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '3':
      this.hPliego = 70;
      this.vPliego = 100;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 70*escala;
      this.heightCanvas = 100*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '4':
      this.hPliego = 50;
      this.vPliego = 70;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 50*escala;
      this.heightCanvas = 70*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '5':
      this.hPliego = 32;
      this.vPliego = 47;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 32*escala;
      this.heightCanvas = 47*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '6':
      this.hPliego = 33;
      this.vPliego = 23;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 33*escala;
      this.heightCanvas = 23*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '7':
      this.hPliego = 84.1;
      this.vPliego = 118.9;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 84.1*escala;
      this.heightCanvas = 118.9*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '8':
      this.hPliego = 59.4;
      this.vPliego = 84.1;
      b = Math.max(this.hPliego, this.vPliego);
      h = Math.min(this.hPliego, this.vPliego);
      escala = 380/b;
      this.widthCanvas = 59.4*escala;
      this.heightCanvas = 84.1*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
      break;
    case '9':
        this.hPliego = 42;
        this.vPliego = 59.4;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 42*escala;
      this.heightCanvas = 59.4*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '10':
        this.hPliego = 29.7;
        this.vPliego = 42;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 29.7*escala;
        this.heightCanvas = 42*escala;
        this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '11':
        this.hPliego = 21;
        this.vPliego = 29.7;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 21*escala;
      this.heightCanvas = 29.7*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '12':
        this.hPliego = 14.8;
        this.vPliego = 21;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 14.8*escala;
      this.heightCanvas = 21*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '13':
        this.hPliego = 10.5;
        this.vPliego = 14.8;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 10.5*escala;
      this.heightCanvas = 14.8*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '14':
        this.hPliego = 7.4;
        this.vPliego = 10.5;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 7.4*escala;
      this.heightCanvas = 10.5*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '15':
        this.hPliego = 5.2;
        this.vPliego = 7.4;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 5.2*escala;
      this.heightCanvas = 7.4*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '16':
        this.hPliego = 3.7;
        this.vPliego = 5.2;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 3.7*escala;
      this.heightCanvas = 5.2*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '17':
        this.hPliego = 2.6;
        this.vPliego = 3.7;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 2.6*escala;
      this.heightCanvas = 3.7*escala;
      this.costoHoja = 0;
      if(this.validacionCampos()===1){
        this.orientacionMax()
      }
        break;
    case '18':
        this.hPliego = 29;
        this.vPliego = 49;
        b = Math.max(this.hPliego, this.vPliego);
        h = Math.min(this.hPliego, this.vPliego);
        escala = 380/b;
        this.widthCanvas = 29*escala;
        this.heightCanvas = 49*escala;
        console.log(this.widthCanvas, this.heightCanvas);
        this.costoHoja = 0;
        if(this.validacionCampos()===1){
          this.orientacionMax()
        }
          break;
    default:
      this.hPliego = 0;
      this.vPliego = 0;
      if(window.screen.width < 380){

        this.widthCanvas = 200;
        this.heightCanvas = 350;
  
      }else{
        this.widthCanvas = 300;
        this.heightCanvas = 450;
      }
      this.widthCanvas = 300;
      this.heightCanvas = 450;
      this.costoHoja = 0;
  }

  }
}


