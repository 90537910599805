import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.css']
})
export class TarjetasComponent implements OnInit {

  isCheckedCaras: boolean = true;
  cantTarjetas: number = 1000;
  imgtarjeta1: string = 'assets/img/tiroTarjeta.png';
  imgtarjeta2: string = 'assets/img/retiroTarjeta4x0.png';
  valueType: any = '1'
  descripcion: string = ': Tarjetas plastificado brillante, full color por una sola cara'
  isCheckedisenio: boolean = true;
  isCheckedEsquinas: boolean = false;
  flagCotizar: boolean = false;
  anchoTarjeta: number = 9;
  altoTarjeta: number = 5.5;
  tipoTarjeta: any = 'Brillante 4x0';
  disenio: any = 'Si';
  esqinas: any = 'Rectas';
  valorUnidad2: any;
  totalNeto2:any;
  valorIva2: any;
  valorTotal2:any;

  constructor() { }

  ngOnInit(): void {
  }

  onCarasChange(e: any){
    this.isCheckedCaras = !this.isCheckedCaras;
  }

  onCantTarjetasChange(value: number){

    if(value == 0){
      this.cantTarjetas = 1000;
    }

    if(value%1000 !=0){
       this.cantTarjetas = (Math.ceil(value/1000))*1000;
    }

    if(this.flagCotizar == true){
      this.cotizar();
    }

  }


  onTypeChange(value:any){

    if(value == '1'){
      this.tipoTarjeta = 'Brillante 4x0';
      this.descripcion = ': Tarjetas plastificado brillante, full color por una sola cara';
      this.isCheckedCaras = true;
      if(this.isCheckedEsquinas == true){
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x0redonda.png';
      }else{
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x0.png';
      }
    }else if(value == '2'){
      this.tipoTarjeta = 'Brillante 4x2';
      this.descripcion = ': Tarjetas plastificado brillante, full color por una cara y a dos tintas por el reverso';
      this.isCheckedCaras = true;
      if(this.isCheckedEsquinas == true){
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x2redondo.png';
      }else{
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x2.png';
      }
      
    }else if(value == '3'){
      this.descripcion = ': Tarjetas plastificado brillante, full color por ambas caras';
      this.tipoTarjeta = 'Brillante 4x4';
      this.isCheckedCaras = true;
      if(this.isCheckedEsquinas == true){
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x4redondo.png';
      }else{
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta.png';
      }
    }else if(value == '4'){
      this.descripcion = ': Tarjetas plastificado mate y brillo UV, full color por ambas caras';
      this.tipoTarjeta = 'Mate 4x4';
      this.isCheckedCaras = true;
      if(this.isCheckedEsquinas == true){
        this.imgtarjeta1 = 'assets/img/tarjetaBordeRedondo1.png';
        this.imgtarjeta2 = 'assets/img/tarjetaBordeRedondo2.png';
      }else{
        this.imgtarjeta1 = 'assets/img/tarjetaMate1.png';
        this.imgtarjeta2 = 'assets/img/tarjetaMate2.png';     
      }
    }else if(value == '5'){
      this.tipoTarjeta = 'Adhesivas 4x0';
      this.descripcion = ': Tarjetas plastificado brillante adhesivas, full color por una sola cara';
      this.isCheckedCaras = false;
      if(this.isCheckedEsquinas == true){
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
      //  this.imgtarjeta2 = 'assets/img/retiroTarjeta4x2.png';
      }else{
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
       // this.imgtarjeta2 = 'assets/img/retiroTarjeta4x2.png'; 
      }
    }

    if(this.flagCotizar == true){
      this.cotizar();
    }

  }

  onDisenioChange(e: any){

    this.isCheckedisenio = !this.isCheckedisenio;
    if(this.isCheckedisenio == true){
      this.disenio = 'Si';
    }else{
      this.disenio = 'No';
    }

    if(this.flagCotizar == true){
      this.cotizar();
    }

  }

  onEsquinasChange(e: any){

    this.isCheckedEsquinas = !this.isCheckedEsquinas;

    if(this.isCheckedEsquinas == true){
      this.esqinas = 'Redondas';
      if(this.valueType == '1'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x0redonda.png';
      }else if(this.valueType == '2'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x2redondo.png';
      }else if(this.valueType == '3'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x4redondo.png';
      }else if(this.valueType == '4'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tarjetaBordeRedondo1.png';
        this.imgtarjeta2 = 'assets/img/tarjetaBordeRedondo2.png';
      }else if(this.valueType == '5'){
        this.isCheckedCaras = false;
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
      }
    }else{
      this.esqinas = 'Rectas';
      if(this.valueType == '1'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x0.png';
      }else if(this.valueType == '2'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x2.png';
      }else if(this.valueType == '3'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tiroTarjetaRedondo.png';
        this.imgtarjeta2 = 'assets/img/retiroTarjeta4x4redondo.png';
      }else if(this.valueType == '4'){
        this.isCheckedCaras = true;
        this.imgtarjeta1 = 'assets/img/tarjetaMate1.png';
        this.imgtarjeta2 = 'assets/img/tarjetaMate2.png'; 
      }else if(this.valueType == '5'){
        this.isCheckedCaras = false;
        this.imgtarjeta1 = 'assets/img/tiroTarjeta.png';
      }
    }


    if(this.flagCotizar == true){
      this.cotizar();
    }


  }

  cotizar(){

    

    let valorUnidad = 0;
    let valorIva = 0;
    let valorNeto = 0;
    let valorTotal = 0;

    if(this.valueType == '1'){

      valorUnidad = 25113;

      if(this.isCheckedisenio == true){
        valorUnidad += 10000;
      }

    }else if(this.valueType == '2'){

      valorUnidad = 25113;
      if(this.isCheckedisenio == true){
        valorUnidad += 15000;
      }

    }else if(this.valueType == '3'){

      valorUnidad = 33910;

      if(this.isCheckedisenio == true){
        valorUnidad += 20000;
      }

    }else if(this.valueType == '4'){

      valorUnidad = 60225.6;

      if(this.isCheckedisenio == true){
        valorUnidad += 20000;
      }

    }else if(this.valueType == '5'){

      valorUnidad = 43910;

      if(this.isCheckedisenio == true){
        valorUnidad += 10000;
      }

    }

    if(this.isCheckedEsquinas == true){
      valorUnidad += 10000;
    }

    let valorSinTransporte = valorUnidad;

    valorUnidad += (10000/(this.cantTarjetas/1000));

    valorUnidad = valorUnidad*1.33;

    valorNeto = valorUnidad * (this.cantTarjetas/1000);

    valorIva = valorNeto * 0.19;

    valorTotal = valorNeto+valorIva;


    const formatterPeso = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    })

     this.totalNeto2 = formatterPeso.format(valorNeto);
     this.valorUnidad2 = formatterPeso.format(valorUnidad);
     this.valorIva2 = formatterPeso.format(valorIva);
     this.valorTotal2 = formatterPeso.format(valorTotal);

     this.flagCotizar = true;
  }

}
