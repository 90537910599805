import { Component, OnInit } from "@angular/core";
import { UserService } from '../../../views/admin/user.service';

@Component({
  selector: "app-card-settings",
  templateUrl: "./card-settings.component.html",
})
export class CardSettingsComponent implements OnInit {
  userData: any;
  constructor(private userService: UserService) {}

  ngOnInit(): void {

    this.userService.getUserData().subscribe(data => {
      this.userData = data;
    });
  }
}
