import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) {}

  getUserData(): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.object(`users/${user.uid}`).valueChanges();
        } else {
          return new Observable(null);
        }
      })
    );
  }

  getUserId(): Observable<string | null> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return of(user.uid);
        } else {
          return of(null);
        }
      })
    );
  }
}
