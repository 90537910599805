import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }


  sendEmail(data): Observable<any> {
    return this.http.post('https://formspree.io/f/xnqrjwew', data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }


}
