import { Component, OnInit } from '@angular/core';
import { EmailService  } from './emailService/email.service';



@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['../digital/digital.component.css']
})
export class ContactanosComponent implements OnInit {

  textMensaje: any;
  phoneNumber: any;
  textEmail: any;
  textName: any;

  constructor(
   // private emailService: EmailService
   private emailService: EmailService,
    ) { }

  ngOnInit(): void {
  }


  enviarCorreo(e :any){
    console.log(e);

    if(this.textName == null || this.phoneNumber == null || this.textEmail == null || this.textMensaje == null){

      alert("Por favor llene todos los campos del formulario para tener en cuenta su solicitud");

    }else{

      if(this.textEmail.indexOf('@') != -1){

        let number = String(this.phoneNumber);

        if(number.length != 10){
          alert('Se requiere un número de teléfono válido para Colombia.')
        }else{

          const dataEmail: any = {
            name: this.textName,
            email: this.textEmail,
            message: this.textMensaje+" - Teléfono - "+this.phoneNumber
          }

          this.emailService.sendEmail(dataEmail).subscribe((data:any) => {

            alert('Hemos recibido su mensaje, nos pondremos en contacto con usted muy pronto.');

            window.location.reload();
          }, error => {
            console.log('Error al enviar el correo: ', error);
          });

        }

      }else{
        alert('El correo electrónico especificado no es correcto, por favor verfique de nuevo.');
      }

    }
  }

}
