import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-conversation-item',
  templateUrl: './conversation-item.component.html',
  styleUrls: ['./conversation-item.component.css']
})
export class ConversationItemComponent implements OnInit {
  @Input() data: any;
  @Output() itemClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  onClick() {
    this.itemClicked.emit(this.data.key);
  }

  getTimeDifference(timestamp: string): string {
    if (!timestamp) return '';

    const date = new Date(timestamp);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - date.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30.4375); // Aproximación de meses

    if (diffInMonths >= 1) {
      return `${diffInMonths} ${diffInMonths > 1 ? 'meses' : 'mes'}`;
    } else if (diffInDays >= 1) {
      return `${diffInDays} ${diffInDays > 1 ? 'dias' : 'dia'}`;
    } else if (diffInHours >= 1) {
      return `${diffInHours} ${diffInHours > 1 ? 'horas' : 'hora'}`;
    } else if (diffInMinutes >= 1) {
      return `${diffInMinutes} ${diffInMinutes > 1 ? 'minutos' : 'minuto'}`;
    } else {
      return `${diffInSeconds} ${diffInSeconds > 1 ? 'segundos' : 'segundo'}`;
    }
  }


  getLastMessage(): any {
    if (this.data && this.data.mensajes) {
      const mensajes: any = Object.values(this.data.mensajes);
      if (mensajes.length > 0) {
        return mensajes[0];
      }
    }
    return 'No hay mensajes';
  }
}
