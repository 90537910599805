import { Component, OnInit } from "@angular/core";
import { RegisterService } from "../auth/register/register.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent implements OnInit {
  token: any;
  constructor(private registerService: RegisterService, private router:Router) {
    this.token = localStorage.getItem("token");
  }

  ngOnInit(): void {
    this.tokenValidator();
  }

  onClickUsers(){
    
  }

  tokenValidator(){
    this.registerService.getUser({'token': this.token}).subscribe(
      (data:any) => {
        console.log('El token es válido', data);    
        if(data.email == 'graficoimpresores@gmail.com' || data.email == 'fabbagar83@gmail.com'){
          console.log('El usuario es administrador'); 
        }else{
          console.log('Usuario no válido'); 
          localStorage.removeItem('token');
          localStorage.removeItem('email');
          this.router.navigate(['/auth/login']);
        }
       // alert(data);
     }, error => {
       console.log('Token invalido: ', error);
       //alert(error);
       localStorage.removeItem('token');
       localStorage.removeItem('email');
       this.router.navigate(['/auth/login']);
     });
  }
}
