import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-andhesivos',
  templateUrl: './andhesivos.component.html',
  styleUrls: ['./andhesivos.component.css']
})
export class AndhesivosComponent implements OnInit, AfterViewInit {

  @ViewChild('myCanvas')
  private myCanvas: ElementRef = {} as ElementRef;

  valueMaterial:any = '1';
  flagVinilo: any = false;
  isCheckedSalpicaduras:boolean = false;
  cantDisenios: number = 1;
  isCheckedTroquel: boolean = false;
  valueFormaTroquel: any = 1;
  widthCanvas: number = 0;
  heightCanvas: number = 0;
  cantidadAdhe: number = 0;
  anchoAdhe: number = 0;
  altoAdhe: number = 0;
  altoVinilo: number = 27.5;
  anchoVinilo: number = 47;
  canvas: any;
  context: any;
  context2: any;
  cortesPliego:any =0;
  cortesUtilizables: any = 0;
  pliegos: any = 0;
  noCortes: any = 0;
  bgCanvasColor: string = '#ecdac4';
  flagMaterial:boolean = true;
  isCheckedDisenio: boolean = false;
  isCheckedPlastificado: boolean = false;
  valueTipoPlastificado:any = "1";
  isCheckedFondo: boolean = true;
  flagCotizar: boolean = false;
  tipoMaterial: any = "";
  plastificado: any = "";
  tipoToquelado:any = "";
  tipoFondo:any = "";
  disenio: any = "";
  valorUnidad2:any;
  totalNeto2:any;
  valorIva2:any;
  valorTotal2:any;
  anchoTroquel:any = 0;
  altoTroquel:any = 0;
  img: any = new Image();
  img2: any = new Image();
  flagSpinner: boolean = false;
  constructor() { 
    this.img.src = './assets/img/original4.webp';
    this.img2.src = './assets/img/original5.webp';
  }

  ngOnInit(): void {
    this.setCanvasSize();
  }
  ngAfterViewInit(): void {
    this.canvas = document.getElementById("micanvas");
    this.context = this.myCanvas.nativeElement.getContext('2d');
    this.context2 = this.myCanvas.nativeElement.getContext('2d');
  }

  // Funciones para el canvas

  clearCanvas() {

    this.context.clearRect(0, 0, this.widthCanvas, this.heightCanvas);
 
  }
   orientacionV(val:number){

   
    var cortes, totalCortes, sobrante;

    if(this.validacionCampos() == 1){
      var b = Math.max(this.anchoVinilo, this.altoVinilo);
      var h = Math.min(this.anchoVinilo, this.altoVinilo);
      console.log('ancho: ', window.screen.width);
      if(window.screen.width < 470){
        var escala = 250/b;
      }else{
        var escala = 380/b;
      }
     
      var cortesV, cortesH = 0;

      this.clearCanvas();
      if(val == 1){
        this.widthCanvas = h*escala;
        this.heightCanvas = b*escala;
      }else if(val == 2){
        this.widthCanvas = b*escala;
        this.heightCanvas = h*escala;
      }
      
      
      setTimeout(() => {

        if(val == 1){
          cortes = this.acomodarCortes(b,h,"N","V");
        }else if(val == 2){
          cortes = this.acomodarCortes(b,h,"N","H");
        }
        
        totalCortes = cortes.cortesT;

        console.log('cortes: ', cortes);

        
          this.dibujarCuadricula(cortes.cortesB, cortes.cortesH, this.anchoTroquel, this.altoTroquel, 0, 0, escala, '');
  
       
  
      if (cortes.sobranteB >= this.altoTroquel) {
          sobrante = this.acomodarCortes(cortes.sobranteB, b, "H", "H");
          totalCortes += sobrante.cortesT;
          this.dibujarCuadricula(sobrante.cortesH, sobrante.cortesB, this.altoTroquel, this.anchoTroquel, cortes.cortesB * this.anchoTroquel * escala, 0, escala, "R");
      } else if (cortes.sobranteH >= this.anchoTroquel) {
          sobrante = this.acomodarCortes(cortes.sobranteH, h, "H", "H");
          totalCortes += sobrante.cortesT;
          this.dibujarCuadricula(sobrante.cortesB, sobrante.cortesH, this.altoTroquel, this.anchoTroquel, 0, cortes.cortesH * this.altoTroquel * escala, escala, "R");
      } else {
          sobrante = {
              cortesT: 0,
              cortesB: 0,
              cortesH: 0,
              sobranteB: 0,
              sobranteH: 0,
              areaUtilizada: 0
          };
      }

      if (this.anchoTroquel < this.altoTroquel) {
        cortesV = cortes.cortesT;
        cortesH = sobrante.cortesT;
    } else {
        cortesV = sobrante.cortesT;
        cortesH = cortes.cortesT;
    }
    
    
      this.calcularArea(b, h, this.anchoTroquel, this.altoTroquel, totalCortes);
      this.calcular(b, h, cortesV, cortesH, totalCortes, cortes.cortesT, "V");

      }, 50);

    }

  }

  orientacionMax(){
    if(this.validacionCampos() === 1) {

      this.flagSpinner = true;
     // this.orientacion = "Maximo";
      var b = Math.max(this.anchoVinilo,this.altoVinilo);
      var h = Math.min(this.anchoVinilo,this.altoVinilo);
      var cb = Math.max(this.anchoTroquel,this.altoTroquel);
      var ch = Math.min(this.anchoTroquel,this.altoTroquel);
      console.log('ancho: ', window.screen.width);
      if(window.screen.width < 470){
        var escala = 250/b;
      }else{
        var escala = 380/b;
      }
      var a1h = h;
      var a1b = b;
      var a2h, a2b, sumaCortes = 0;
      var corteA1, corteA2;
      var totalCortes;
      var acomodo1: any , acomodo2: any = {
          a1b: "",
          a2b: "",
          a1h: "",
          a2h: "",
          sumaCortes: "" 
      };
      
      this.clearCanvas();
      this.widthCanvas = b*escala;
      this.heightCanvas = h*escala;
      
     
      setTimeout(() => {
        var cortes = this.acomodarCortes(b, h, "H", "M");
      
      totalCortes = cortes.cortesT;
      acomodo1 = {
          a1b: b, 
          a2b: b, 
          a1h: h, 
          a2h: 0, 
          sumaCortes: totalCortes, 
          cortesH1: cortes.cortesH,
          cortesB1: cortes.cortesB,
          cortesT1: cortes.cortesT,
          cortesH2: 0,
          cortesB2: 0,
          cortesT2: 0
      };
      
      for (var x = 0; x <= cortes.cortesH; x++) {
          a2b = b;
          
          a2h = parseFloat(((ch * x) + cortes.sobranteH).toFixed(2));
          a1h = parseFloat((h - a2h).toFixed(2));
          
          corteA1 = this.acomodarCortes(a1b, a1h, "H", "N");
          corteA2 = this.acomodarCortes(a2b, a2h, "V", "N");
          
          sumaCortes = corteA1.cortesT + corteA2.cortesT;
          
          if(sumaCortes > totalCortes) {
              totalCortes = sumaCortes;
              acomodo1 = {
                  a1b: a1b, 
                  a2b: a2b, 
                  a1h: a1h, 
                  a2h: a2h, 
                  sumaCortes: totalCortes, 
                  cortesH1: corteA1.cortesH, 
                  cortesB1: corteA1.cortesB,
                  cortesT1: corteA1.cortesT,
                  cortesH2: corteA2.cortesH,
                  cortesB2: corteA2.cortesB,
                  cortesT2: corteA2.cortesT
              };
          }
          //console.log("x: " + x + " corte A1: " + corteA1.cortesT + " corte A2: " + corteA2.cortesT + " cortes: " + sumaCortes);
      }
      
      //console.log(acomodo1.sumaCortes);
      
      totalCortes = cortes.cortesT;
      var acomodo2: any = {a1b: b, a2b: 0, a1h: h, a2h: h, sumaCortes: totalCortes, cortesH: totalCortes, cortesV: 0};
      
      for (x = 0; x <= cortes.cortesB; x++) {
         var a2h, a1h = h;
          
          a2b = parseFloat(((cb * x) + cortes.sobranteB).toFixed(2));
          a1b = parseFloat((b - a2b).toFixed(2));
          
          corteA1 = this.acomodarCortes(a1b, a1h, "H", "N");
          corteA2 = this.acomodarCortes(a2b, a2h, "V", "N");
          
          sumaCortes = corteA1.cortesT + corteA2.cortesT;
          
          if(sumaCortes > totalCortes) {
              totalCortes = sumaCortes;
              acomodo2 = {
                  a1b: a1b, 
                  a2b: a2b, 
                  a1h: a1h, 
                  a2h: a2h, 
                  sumaCortes: totalCortes, 
                  cortesH1: corteA1.cortesH, 
                  cortesB1: corteA1.cortesB,
                  cortesT1: corteA1.cortesT,
                  cortesH2: corteA2.cortesH,
                  cortesB2: corteA2.cortesB,
                  cortesT2: corteA2.cortesT
              };
          }
          //console.log("x: " + x + " corte A1: " + corteA1.cortesT + " corte A2: " + corteA2.cortesT + " cortes: " + sumaCortes);
      }
      
      //console.log(acomodo2.sumaCortes);
      
      if(acomodo2.sumaCortes > acomodo1.sumaCortes) {
          //Calculando el area
          this.calcularArea(b, h, cb, ch, acomodo2.sumaCortes);
          this.calcular(b, h, acomodo2.cortesT2, acomodo2.cortesT1, parseInt(acomodo2.sumaCortes), acomodo2.sumaCortes, "M");
          //console.log("Corte V: " + acomodo2.sumaCortes);
          //Dibuja 2 areas una al lado de otra
          this.dibujarCuadricula(acomodo2.cortesB1, acomodo2.cortesH1, cb, ch, 0, 0, escala, '');
          this.dibujarCuadricula(acomodo2.cortesB2, acomodo2.cortesH2, ch, cb, acomodo2.cortesB1 * cb * escala, 0, escala, '');
      } else {
          //Calculando el area
          this.calcularArea(b, h, cb, ch, acomodo1.sumaCortes);
          this.calcular(b, h, acomodo1.cortesT2, acomodo1.cortesT1, acomodo1.sumaCortes, parseInt(acomodo1.sumaCortes), "M");
          //console.log("Corte H: " + acomodo1.sumaCortes);
          //Dibuja 2 areas una arriba de otra
          this.dibujarCuadricula(acomodo1.cortesB1, acomodo1.cortesH1, cb, ch, 0, 0, escala,'');
          this.dibujarCuadricula(acomodo1.cortesB2, acomodo1.cortesH2, ch, cb, 0, acomodo1.cortesH1 * ch * escala, escala,'');
      }
      }, 50);
  }
  }

  validacionCampos(){
    var valida = 1;

    if(this.anchoVinilo == 0 || this.anchoVinilo == null || this.altoVinilo == 0 || this.altoVinilo == null || this.anchoAdhe == 0 || this.anchoAdhe == null || this.altoAdhe == null || this.altoAdhe == 0 || this.cantidadAdhe == 0 || this.cantidadAdhe == null ){
      valida = 0;
    }

    return valida;
    
  }

  acomodarCortes(base, altura, acomodoCorte, acomodoPliego){
     
      var cb = 1;
      var ch = 1;
      var b = 1;
      var h = 1;
      
      if(acomodoPliego === "V") {
          b = Math.min(base, altura);
          h = Math.max(base, altura);
      } else if (acomodoPliego === "H") {
      /* Acomodo del pliego en horizontal y para el calculo del maximo
       */
          b = Math.max(base, altura);
          h = Math.min(base, altura);        
      } else {
          b = base;
          h = altura;
      }
     

        if (acomodoCorte === 'H') {
          cb = Math.max(this.anchoTroquel, this.altoTroquel);
          ch = Math.min(this.anchoTroquel, this.altoTroquel);       
        } else if(acomodoCorte === 'V') {
          cb = Math.min(this.anchoTroquel, this.altoTroquel);
          ch = Math.max(this.anchoTroquel, this.altoTroquel);   
        } else {
          cb = this.anchoTroquel;
          ch = this.altoTroquel;
        }

      //  console.log('anchoAdhe', this.anchoTroquel)
      //  console.log('altoAdhe', this.altoTroquel)
      
      
      var cortesT = (Math.trunc(b / cb)) * (Math.trunc(h / ch));
      var cortesB = Math.trunc(b / cb);
      var cortesH = Math.trunc(h / ch);
      var sobranteB = parseFloat((b - (cortesB * cb)).toFixed(2));
      var sobranteH = parseFloat((h - (cortesH * ch)).toFixed(2));
      var areaUtilizada = parseFloat(((cb * ch) * (Math.trunc(b / cb)) * (Math.trunc(h / ch))).toFixed(2));
      
      
      var cortes = {
          cortesT: cortesT,
          cortesB: cortesB,
          cortesH: cortesH,
          sobranteB: sobranteB,
          sobranteH: sobranteH,
          areaUtilizada: areaUtilizada
      };
      
      return cortes;
  
  }
  calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) { 
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight); 
    return { width: srcWidth*ratio, height: srcHeight*ratio }; 
  }

  rotateAndPaintImage ( context, image, angleInRad , positionX, positionY, axisX, axisY, sizeX, sizeY ) {
    context.translate( positionX, positionY );
    context.rotate( angleInRad );
    context.drawImage( image, -axisX, -axisY, sizeX, sizeY );
    context.rotate( -angleInRad );
    context.translate( -positionX, -positionY );
  }

  dibujarCuadricula(cortesX, cortesY, width, height, coorX, coorY, escala, color){

      var TO_RADIANS = Math.PI/180; 

     // console.log('CotesX: ', cortesX);
     // console.log('CotesY: ', cortesY);
    

      if(color === "R") {
        color = ' #FF9D9D';
      } else {
        color = '#ecdac4';
      }
    
   //   this.img.style.transform = 'rotate(90deg)';
       
       var coorY1 = coorY;
       var coorX1 = coorX;
    
       width = escala * width;
       height = escala * height;
    
       
       
       for (var x = 1; x <= cortesX; x++) {
        
          coorY = coorY1;
        
          for (var y = 1; y <=cortesY; y++) {

            

            if(this.valueFormaTroquel == '1'){

              if(width >= height){
                let imgWidth = this.calculateAspectRatioFit(this.img.width, this.img.height, width-5, height-5).width;
                let imgHeight = this.calculateAspectRatioFit(this.img.width, this.img.height, width-5, height-5).height;

                this.context.fillStyle = 'transparent';
                this.context.beginPath();
                this.context.rect(coorX, coorY, width, height);
                this.context.globalCompositeOperation = "source-over";
                if(width > imgWidth || height > imgHeight){
                if(height > imgHeight){

                  if(width > imgWidth){
                    this.context.drawImage(this.img, coorX+((width/2)-(imgWidth/2)), coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  //  this.rotateAndPaintImage ( this.context, this.img, 90*TO_RADIANS, coorX, coorY, coorX+((width/2)-(imgWidth/2)), coorX+((width/2)-(imgWidth/2)), imgWidth, imgHeight);
                  }else{
                    //this.context.translate( coorX, coorY );
                    this.context.drawImage(this.img, coorX, coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }

                }else{

                  this.context.drawImage(this.img, coorX+((width/2)-(imgWidth/2)), coorY, imgWidth, imgHeight);

                }

              }else{

                this.context.drawImage(this.img, coorX, coorY, imgWidth, imgHeight);

              }
              }else{
                let imgWidth = this.calculateAspectRatioFit(this.img2.width, this.img2.height, width-5, height-5).width;
                let imgHeight = this.calculateAspectRatioFit(this.img2.width, this.img2.height, width-5, height-5).height;
                this.context2.fillStyle = 'transparent';
                this.context2.beginPath();
                this.context2.rect(coorX, coorY, width, height);
                this.context2.globalCompositeOperation = "source-over";
                if(width > imgWidth || height > imgHeight){
                  if(height > imgHeight){
  
                    if(width > imgWidth){
                      this.context2.drawImage(this.img2, coorX+((width/2)-(imgWidth/2)), coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                    //  this.rotateAndPaintImage ( this.context, this.img, 90*TO_RADIANS, coorX, coorY, coorX+((width/2)-(imgWidth/2)), coorX+((width/2)-(imgWidth/2)), imgWidth, imgHeight);
                    }else{
                      //this.context.translate( coorX, coorY );
                      this.context2.drawImage(this.img2, coorX, coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                    }
  
                  }else{
  
                    this.context2.drawImage(this.img2, coorX+((width/2)-(imgWidth/2)), coorY, imgWidth, imgHeight);
  
                  }
  
                }else{
  
                  this.context2.drawImage(this.img2, coorX, coorY, imgWidth, imgHeight);
  
                }
              }

              
          
            }else if(this.valueFormaTroquel == '3'){
              let imgWidth = this.calculateAspectRatioFit(this.img.width, this.img.height, width-5, height-5).width;
              let imgHeight = this.calculateAspectRatioFit(this.img.width, this.img.height, width-5, height-5).height;
              this.context.fillStyle = 'transparent';
              this.context.beginPath();
              let radius = width/2;
              this.context.arc(coorX+radius, coorY+radius, radius, 0, 2 * Math.PI, false);

              if(width > imgWidth || height > imgHeight){
                if(height > imgHeight){

                  if(width > imgWidth){
                    this.context.drawImage(this.img, coorX+((width/2)-(imgWidth/2)), coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }else{
                    this.context.drawImage(this.img, coorX, coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }

                }else{

                  this.context.drawImage(this.img, coorX+((width/2)-(imgWidth/2)), coorY, imgWidth, imgHeight);

                }

              }else{
                this.context.drawImage(this.img, coorX, coorY, imgWidth, imgHeight);
              }
      
            }else if(this.valueFormaTroquel == '2'){

              if(width >= height){
                let imgWidth = this.calculateAspectRatioFit(this.img.width, this.img.height, width-5, height-5).width;
                let imgHeight = this.calculateAspectRatioFit(this.img.width, this.img.height, width-5, height-5).height;
                this.context.fillStyle = color;
                this.context.beginPath();
                if(width > imgWidth || height > imgHeight){
                if(height > imgHeight){

                  if(width > imgWidth){
                    this.context.drawImage(this.img, coorX+((width/2)-(imgWidth/2)), coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }else{
                    this.context.drawImage(this.img, coorX, coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }

                }else{

                  this.context.drawImage(this.img, coorX+((width/2)-(imgWidth/2)), coorY, imgWidth, imgHeight);

                }

              }else{
                this.context.drawImage(this.img, coorX, coorY, imgWidth, imgHeight);
              }
              }else{

                let imgWidth = this.calculateAspectRatioFit(this.img2.width, this.img2.height, width-5, height-5).width;
                let imgHeight = this.calculateAspectRatioFit(this.img2.width, this.img2.height, width-5, height-5).height;
                this.context2.fillStyle = color;
                this.context2.beginPath();
                if(width > imgWidth || height > imgHeight){
                if(height > imgHeight){

                  if(width > imgWidth){
                    this.context2.drawImage(this.img2, coorX+((width/2)-(imgWidth/2)), coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }else{
                    this.context2.drawImage(this.img2, coorX, coorY+((height/2)-(imgHeight/2)), imgWidth, imgHeight);
                  }

                }else{

                  this.context2.drawImage(this.img2, coorX+((width/2)-(imgWidth/2)), coorY, imgWidth, imgHeight);

                }

              }else{
                this.context2.drawImage(this.img2, coorX, coorY, imgWidth, imgHeight);
              }

              } 
            }
            
            this.context.fill();
            
            if(this.isCheckedTroquel == true){
              this.context.strokeStyle = '#606264'; 
              this.context.lineWidth = 2;
            }else{
              this.context.strokeStyle = '#ecdac4'; 
              this.context.lineWidth = 1;
            }
                                 
                       
            this.context.stroke();

       
              coorY = (height * y) + coorY1;
            
         }
        
          coorX = (width * x) + coorX1;
          
          
       }

       this.flagSpinner = false;
  }
  calcularArea(ancho_papel, largo_papel, ancho_corte, largo_corte, cortes_en_pliego) {
      var area_papel: number = ancho_papel * largo_papel;
      var area_corte: number = ancho_corte * largo_corte;
  
      var area_utilizada_cortes: number = cortes_en_pliego * area_corte;
  
      var porcentaje_area_utilizada = ((area_utilizada_cortes * 100) / area_papel).toFixed(2);
      var aux: number = ((area_utilizada_cortes * 100) / area_papel);
      var porcentaje_area_inutilizada = (100 - aux).toFixed(2);
  
    //  this.porcAreaUtil = porcentaje_area_utilizada;
    //  this.porcAreaInutil = porcentaje_area_inutilizada;
  }
  calcular(b, h, cortesV, cortesH, totalCortes, utilizables, orientacion) {
      var cortesDeseados = this.cantidadAdhe;
      var pliegosP = 1;
      var pliegos = 0;
      
      if (orientacion === "H") {
          pliegos = Math.ceil(cortesDeseados / utilizables);
        //  alert('Pliegos1 '+pliegos);
      } else if (orientacion === "V") {
          pliegos = Math.ceil(cortesDeseados / utilizables);
        //  alert('Pliegos2 '+pliegos+ ' Cortes Deseados: '+cortesDeseados+' utilizables: '+utilizables);
      } else {
          //Calculando el numero de pliegos necesarios
          pliegos = Math.ceil(cortesDeseados / totalCortes);
        //  alert('Pliegos3 '+pliegos);
      }
    
      if(pliegos !== 0 && !isNaN(pliegos)) {
          pliegosP = pliegos;
      } else if (isNaN(pliegos)) {
          pliegos = 0;
      }
      
     
      
      //Calculando el numero total de cortes en todos los pliegos
      var no_total_cortes = totalCortes * pliegos;

      
      
      //Imprimiendo resultados
      this.imprimirResultados(totalCortes, pliegos, no_total_cortes, cortesH, cortesV, utilizables);
  }
  imprimirResultados(cortes_pliego, pliegos, cortes, cortesH, cortesV, utilizables) {
        this.cortesPliego = Math.trunc(cortes_pliego);
        this.cortesUtilizables = Math.trunc(utilizables);
        this.pliegos = Math.trunc(pliegos);
        this.noCortes = Math.trunc(cortes);
       // this.noCortesV = Math.trunc(cortesV);
       // this.noCortesH = Math.trunc(cortesH);
      
        //this.vTotalHojas = this.costoHoja*pliegos;
  }
  setCanvasSize(){
      var b = Math.max(this.anchoVinilo, this.altoVinilo);
      var h = Math.min(this.anchoVinilo, this.altoVinilo);
      var escala = 380/b;
      this.widthCanvas = h*escala;
      this.heightCanvas = b*escala;
  }

  // Funciones para inputs

  onLaminadoChange(e:any){

    this.isCheckedSalpicaduras = !this.isCheckedSalpicaduras;
 
    this.flagMaterial = !this.isCheckedSalpicaduras;

    if(this.flagCotizar == true){
      this.cotizar();
    }
 
  }
  onTroquelChangeChecked(event: any){
     this.isCheckedTroquel = !this.isCheckedTroquel;
 
     if(this.validacionCampos() == 1){
 
       if(this.isCheckedTroquel == true){
         this.bgCanvasColor = '#f1f5f9';
       }else{
         this.bgCanvasColor = '#ecdac4';
         this.valueFormaTroquel = '1';
       }
 
       this.orientacionMax();
     }

     if(this.flagCotizar == true){
      this.cotizar();
    }
  }
  onTroquelChange(value: any){
 
     if(value == '2'){
       this.altoTroquel = this.altoAdhe+0.2;
       this.anchoTroquel = this.anchoAdhe+0.2;
       this.tipoToquelado = "Personalizado"
     }else if(value == '1'){
       this.tipoToquelado = "Rectangular"
       this.altoTroquel = this.altoAdhe;
       this.anchoTroquel = this.anchoAdhe;
     }else if(value == '3'){
       this.altoAdhe = this.anchoAdhe;
       this.altoTroquel = this.altoAdhe+0.2;
       this.anchoTroquel = this.anchoAdhe+0.2;
       this.tipoToquelado = "Redondo"
     }
 
     if(this.validacionCampos() == 1){
       this.orientacionMax();
     }

     if(this.flagCotizar == true){
      this.cotizar();
    }
 
 
  }
  onCantidadAdheChange(value: number){


    if(this.flagCotizar == true){
      this.cotizar();
    }
 
  }
  onAnchoAdheChange(value: number){

    this.anchoTroquel = this.anchoAdhe;
 
     if(this.anchoAdhe != this.altoAdhe){
       this.valueFormaTroquel = '1';
     }
 
     if(this.validacionCampos() == 1){
       this.orientacionMax();
     }

     if(this.flagCotizar == true){
      this.cotizar();
    }
 
  }
  onAltoAdheChange(value: number){

    this.altoTroquel = this.altoAdhe;
 
     if(this.anchoAdhe != this.altoAdhe){
       this.valueFormaTroquel = '1';
     }
 
     if(this.validacionCampos() == 1){
       this.orientacionMax();
     }
     if(this.flagCotizar == true){
      this.cotizar();
    }
 
  }
  onDisenioChange(e:any){
    this.isCheckedDisenio = !this.isCheckedDisenio;
    if(this.flagCotizar == true){
      this.cotizar();
    }
  }
  onPlastificadoChangeChecked(e:any){
    this.isCheckedPlastificado = !this.isCheckedPlastificado;
    if(this.flagCotizar == true){
      this.cotizar();
    }
  }
  onPlastificadoChange(value:any){
    if(this.flagCotizar == true){
      this.cotizar();
    }
  }
  onFondoChangeChecked(e:any){
    this.isCheckedFondo = !this.isCheckedFondo;
    if(this.flagCotizar == true){
      this.cotizar();
    }
  }

  onCantidadDisenioChange(value: number){
    if(this.flagCotizar == true){
      this.cotizar();
    }
  }

  //Función para cotizar

  cotizar(){

    
    if(this.validacionCampos() == 1){
      this.flagSpinner = true;
      this.orientacionMax();

      let totalUnid = 0;
      let totalNeto = 0;
      let valorIva = 0;
      let totalConIva = 0;

      if(this.isCheckedSalpicaduras == true){
        totalUnid += 7000;
        this.tipoMaterial="Vinilo ahesivo"
        this.plastificado = "N/A";
        if(this.isCheckedFondo==true){
          this.tipoFondo = "Blanco";
        }else{
          this.tipoFondo = "Transparente";
        }
     }else{
        totalUnid += 4500;
        this.tipoMaterial="Adhesivo seguridad"
        this.tipoFondo = "N/A";
        if(this.isCheckedPlastificado == true){
          totalUnid += 1500;

          if(this.valueTipoPlastificado == '1'){
            this.plastificado = "Brillante";
          }else{
            this.plastificado = "Mate";
          }
          
        }else{
          this.plastificado = "No";
        }
     }

     if(this.isCheckedTroquel == true){

       totalUnid += 2000;

       if(this.valueFormaTroquel == '1'){
        this.tipoToquelado = "Rectancular";
       }else if(this.valueFormaTroquel == '3'){
        this.tipoToquelado = "Redondo";
       }else{
        this.tipoToquelado = "Personalizado";
       }

     }else{
      this.tipoToquelado = "No";
     }

     if(this.isCheckedDisenio ==true){

      this.disenio = this.cantDisenios;
      totalUnid += ((20000*this.cantDisenios)/this.pliegos);

     }else{
      this.disenio = "No";
     }


     const formatterPeso = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    });


    setTimeout(() =>{

      totalNeto = totalUnid*this.pliegos;

      valorIva = totalNeto*0.19;

      totalConIva = totalNeto + valorIva;

       this.totalNeto2 = formatterPeso.format(totalNeto);
       this.valorUnidad2 = formatterPeso.format(totalUnid);
       this.valorIva2 = formatterPeso.format(valorIva);
       this.valorTotal2 = formatterPeso.format(totalConIva);

       console.log('totalUnid: ', totalUnid);
       console.log('this.pliegos: ', this.pliegos);
       console.log('this.totalNeto2: ', this.totalNeto2);

       this.flagSpinner = false;

    }, 500)

       


       


     this.flagCotizar = true;


    }

    

  }

  

}
