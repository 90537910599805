import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
  date = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}

  routes(route:String){
    if(route == "fb"){
      //location.href = "https://www.facebook.com/profile.php?id=100063660606251"
      window.open('https://www.facebook.com/profile.php?id=100063660606251', '_blank');
    }else if(route == "ig"){
      //location.href = "https://www.instagram.com/graficoimpresoress.a.s/" 
      window.open('https://www.instagram.com/graficoimpresoress.a.s/', '_blank');
    }else{    
      window.open('https://api.whatsapp.com/send?phone=573142081126', '_blank');
    }
  }
}
