import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cotizafacil',
  templateUrl: './cotizafacil.component.html'
})
export class CotizafacilComponent implements OnInit {

  servicios: any = [];
  noVista: any = "0"

  constructor(private _route: ActivatedRoute) { 

    console.log(this._route.snapshot.paramMap.get('id'));
    this.noVista = this._route.snapshot.paramMap.get('id');

    this.servicios = [
      {
        name: 'Pendones',
        value: 'pendones'
      },
      {
        name: 'Páginas Web',
        value: 'website'
      },
      {
        name: 'Stickers',
        value: 'stickers'
      },
      {
        name: 'Tarjetas de presentación',
        value: 'tarjetas'
      }
    ]
  }

  ngOnInit(): void {

    // Pendiente organizar vector por orden alfabetico

  }

  onChange(value:any){
    console.log(value);

    this.noVista = value;
  }

}
