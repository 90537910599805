// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BASE_URL = 'https://api-grafico-ocbg.onrender.com';

export const environment = {
  production: false,
  apiUrl:'http://localhost:8000/',
  apiKeyFirebase: 'AIzaSyBHxw9TJr-8pavsh8WnXdqNOoF6efJZ7S8',

  firebase: {
    apiKey: "AIzaSyBHxw9TJr-8pavsh8WnXdqNOoF6efJZ7S8",
    authDomain: "grafico-738d8.firebaseapp.com",
    projectId: "grafico-738d8",
    storageBucket: "grafico-738d8.appspot.com",
    messagingSenderId: "327365857365",
    appId: "1:327365857365:web:dfb73685e5ed2e5c0804dc",
    measurementId: "G-2QS47JG23S"
  },

  API_URL_CREAR_NUEVO_CHAT: `${BASE_URL}/new-chat/`,
  API_URL_ENVIAR_MENSAJE: `${BASE_URL}/send/`,
};


