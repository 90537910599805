import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../views/auth/login/auth.service"; // Asegúrate de importar AuthService

@Component({
  selector: "app-user-dropdown",
  templateUrl: "./user-dropdown.component.html",
})
export class UserDropdownComponent implements AfterViewInit {

  constructor(private authService: AuthService, private router: Router) {}

  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }

  toggleDropdown(event) {
    event.preventDefault();
    this.dropdownPopoverShow = !this.dropdownPopoverShow;
  }

  onCloseSesion() {
    this.authService.logout().then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      this.router.navigate(['/auth/login']);
    }).catch(error => {
      console.error('Error al cerrar sesión:', error);
    });
  }
}
