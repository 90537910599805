import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  emailUser: string = '';
  passUser: string = '';
  errorMessage: string = '';
  flagLoading: boolean = false;
  rememberMe: boolean = false; // Variable para recordar credenciales
  flagError: boolean = false; // Agregado para manejar errores de login
  flagSuccess: boolean = false; // Agregado para mensaje de éxito
  flagAlert: boolean = false; // Agregado para alertas
  messageAlert: string = ''; // Agregado para mensaje de alerta

  // Para manejar el tipo de input de contraseña (visible o no)
  typePass: string = 'password';
  showPassword: boolean = false;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    // Verificar si el usuario ya está autenticado al iniciar el componente
    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        console.log("Ya está en sesión");
        this.router.navigate(['/admin/dashboard']); // Redirigir si ya está autenticado
      }
    });
  }

  async onClickLogin() {
    try {
      this.flagLoading = true;
      await this.authService.loginWithEmail(this.emailUser, this.passUser);

      // Si el checkbox de recordar credenciales está marcado, guardar en localStorage
      if (this.rememberMe) {
        localStorage.setItem('email', this.emailUser);
      } else {
        localStorage.removeItem('email'); // Limpiar email en localStorage si no se debe recordar
      }

      this.flagLoading = false;
      this.flagSuccess = true; // Mostrar mensaje de éxito
      this.router.navigate(['/admin/dashboard']);
    } catch (error) {
      this.flagLoading = false;
      this.errorMessage = error.message;
      this.flagError = true; // Mostrar mensaje de error
    }
  }

  // Método para alternar visibilidad de contraseña
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    this.typePass = this.showPassword ? 'text' : 'password';
  }
}
