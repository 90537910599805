import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { createPopper } from "@popperjs/core";

@Component({
  selector: 'app-util-dropdown',
  templateUrl: './util-dropdown.component.html',
  styleUrls: ['./util-dropdown.component.css']
})
export class UtilDropdownComponent implements OnInit {
  dropdownPopoverShow = false;
  arrow: string = "text-blueGray-400 far fa-caret-square-down text-lg leading-lg mr-2";
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  ngOnInit() {

  }
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.arrow = "text-blueGray-400 far fa-caret-square-down text-lg leading-lg mr-2";
      this.dropdownPopoverShow = false;
    } else {
      this.arrow = "text-red-600 far fa-caret-square-up text-lg leading-lg mr-2";
      
      this.dropdownPopoverShow = true;
      this.createPoppper();
    }
  }
  createPoppper() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
}
