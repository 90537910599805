import { Component, OnInit } from '@angular/core';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-cotizapendon',
  templateUrl: './cotizapendon.component.html'
})
export class CotizapendonComponent implements OnInit {

  cantidadPend: number = 0;
  anchoPend: number = 0;
  altoPend: number = 0;
  cantOjaletes: number;
  cantDisenios: number = 1;
  isCheckedPerfiles: boolean = true;
  isCheckedOjales: boolean = false;
  isCheckedNoDisenio: boolean = false;
  isCheckedDisenio: boolean = true;
  imgPendon: string = 'assets/img/pendVerticalPerfiles.png';
  flagRangeBar: boolean = true;
  flagCantDisenios: boolean = false;
  valuePosition: any = '1';
  flagCotizar: boolean = false;
  position: string = 'Vertical';
  perfiles: string = 'Si';
  ojaletes: number = 0;
  disenio: any = 1;
  valorUnidad:number = 0;
  valorUnidad2:any;
  totalNeto: number = 0;
  totalNeto2: any;
  valorIva: number = 0;
  valorIva2: any;
  valorTotal: number = 0;
  valorTotal2: any;

  constructor() { 
    this.cantOjaletes = 4;
  }

  ngOnInit(): void {
    console.log('carga datos');
  }

  onCantidadPendChange(value: any){

    console.log('value: ',value);

    this.flagCotizar = false;

  }

  onAnchoPendChange(value: any){

    console.log('value: ',value);

    this.flagCotizar = false;

    this.verificaAnchoAlto();

  }

  onAltoPendChange(value: any){

    console.log('value: ',value);

    this.flagCotizar = false;

    this.verificaAnchoAlto();

  }

  verificaAnchoAlto(){
    if(this.anchoPend != 0 && this.altoPend != 0){
       if(this.anchoPend > this.altoPend){
          this.valuePosition = '2';
          this.renderImagePendon();
       }else{
         this.valuePosition = '1';
         this.renderImagePendon();
       }
    }
  }

  onPerfilesChange(e:any){

    this.flagCotizar = false;

    if(e.target.checked == true){
      this.isCheckedPerfiles = true;
      this.isCheckedOjales = false;
      this.flagRangeBar = true;
      this.perfiles = 'Si';
    }else{
      this.isCheckedPerfiles = false;
      this.isCheckedOjales = true;
      this.flagRangeBar = false;
      this.perfiles = 'No';
    }

    this.renderImagePendon();

  }

  onOjalesChange(e:any){

    this.flagCotizar = false;

    if(e.target.checked == true){
      this.isCheckedPerfiles = false;
      this.isCheckedOjales = true;
      this.flagRangeBar = false;
      this.perfiles = 'No';
    }else{
      this.isCheckedPerfiles = true;
      this.isCheckedOjales = false;
      this.flagRangeBar = true;
      this.perfiles = 'Si';
    }

    

    this.renderImagePendon();

  }

  onDisenioChange(e:any){

    this.flagCotizar = false;

    if(e.target.checked == true){
      this.isCheckedDisenio = true;
      this.isCheckedNoDisenio = false;
      this.flagCantDisenios = false;
    }else{
      this.isCheckedDisenio = false;
      this.isCheckedNoDisenio = true;
      this.flagCantDisenios = true;
    }

    

  }

  onNotDisenioChange(e:any){

    this.flagCotizar = false

   
    if(e.target.checked == true){
      this.isCheckedDisenio = false;
      this.isCheckedNoDisenio = true;
      this.flagCantDisenios = true;
    }else{
      this.isCheckedDisenio = true;
      this.isCheckedNoDisenio = false;
      this.flagCantDisenios = false;
    }
    
  }

  onPositionChange(value:any){
    
    console.log(value);

    this.flagCotizar = false;

    this.valuePosition = value;

    if(value == '1'){
      this.position = 'Vertical';
      if(this.anchoPend > this.altoPend){
        let aux = this.anchoPend;
        this.anchoPend = this.altoPend;
        this.altoPend = aux;
      }
    }else{
      this.position = 'Horizontal';
      if(this.anchoPend < this.altoPend){
        let aux = this.anchoPend;
        this.anchoPend = this.altoPend;
        this.altoPend = aux;
      }
    }
    
    this.renderImagePendon();
  }

  renderImagePendon(){
    if(this.valuePosition == '1'){
        if(this.isCheckedOjales == true){    
           switch(this.cantOjaletes) { 
            case 6: { 
              this.imgPendon = 'assets/img/pendVertical6Ojales.png';
               break; 
            } 
            case 8: { 
              this.imgPendon = 'assets/img/pendVertical8Ojales.png';
              break; 
            }
            case 10: { 
              this.imgPendon = 'assets/img/pendVertical10Ojales.png';
              break; 
            }
            case 12: { 
              this.imgPendon = 'assets/img/pendVertical12Ojales.png';
              break; 
            }
            case 14: { 
              this.imgPendon = 'assets/img/pendVertical14Ojales.png';
              break; 
            }
            case 16: { 
              this.imgPendon = 'assets/img/pendVertical16Ojales.png';
              break; 
            }
            case 18: { 
              this.imgPendon = 'assets/img/pendVertical18Ojales.png';
              break; 
            }
            case 20: { 
              this.imgPendon = 'assets/img/pendVertical20Ojales.png'; 
              break; 
            }
            default: { 
              this.imgPendon = 'assets/img/pendVertical4Ojales.png';
               break; 
            } 
         } 

        }else{
          this.imgPendon = 'assets/img/pendVerticalPerfiles.png';
        }
        
    }else{
      if(this.isCheckedOjales == true){

        switch(this.cantOjaletes) { 
          case 6: { 
            this.imgPendon = 'assets/img/pendHorizontal6Ojales.png';
             break; 
          } 
          case 8: { 
            this.imgPendon = 'assets/img/pendHorizontal8Ojales.png';
            break; 
          }
          case 10: { 
            this.imgPendon = 'assets/img/pendHorizontal10Ojales.png';
            break; 
          }
          case 12: { 
            this.imgPendon = 'assets/img/pendHorizontal12Ojales.png';
            break; 
          }
          case 14: { 
            this.imgPendon = 'assets/img/pendHorizontal14Ojales.png';
            break; 
          }
          case 16: { 
            this.imgPendon = 'assets/img/pendHorizontal16Ojales.png';
            break; 
          }
          case 18: { 
            this.imgPendon = 'assets/img/pendHorizontal18Ojales.png';
            break; 
          }
          case 20: { 
            this.imgPendon = 'assets/img/pendHorizontal20Ojales.png'; 
            break; 
          }
          default: { 
            this.imgPendon = 'assets/img/pendHorizontal4Ojales.png';
             break; 
          } 
        } 

      }else{
        this.imgPendon = 'assets/img/pendHorizontalPerfiles.png';
      }
        
    }
  }

  onCantOjaletesChange(value:any){
    
   this.flagCotizar = false;

    this.renderImagePendon()
  }

  cotizar(){

    if(this.isCheckedOjales == true){
        this.ojaletes = this.cantOjaletes;
    }else{
      this.ojaletes = 0;
    }

    if(this.isCheckedDisenio == true){
      this.disenio = this.cantDisenios;
    }else{
      this.disenio = 0;
    }

    if(this.anchoPend >= this.altoPend){
      this.position = 'Horizontal';
    }else{
      this.position = 'Vertical';
    }

    if(this.cantidadPend == 0){
      alert('Por favor ingrese la cantidad de pendones');
    }else if(this.anchoPend == 0 || this.altoPend == 0){
      alert('Por favor ingrese las medidas del pendón (Horizontal y Vertical)');
    }else{
       
      this.valorUnidad = (this.anchoPend*this.altoPend*4.5)

       if(this.isCheckedPerfiles == true){
            this.valorUnidad += (this.anchoPend*200);
       }else if(this.isCheckedOjales == true){
        switch(this.cantOjaletes) { 
          case 6: { 
            this.valorUnidad += (this.cantOjaletes*2000);
             break; 
          } 
          case 8: { 
            this.valorUnidad += (this.cantOjaletes*1900);
            break; 
          }
          case 10: { 
            this.valorUnidad +=(this.cantOjaletes*1800);
            break; 
          }
          case 12: { 
            this.valorUnidad += (this.cantOjaletes*1700);
            break; 
          }
          case 14: { 
            this.valorUnidad += (this.cantOjaletes*1700);
            break; 
          }
          case 16: { 
            this.valorUnidad += (this.cantOjaletes*1600);
            break; 
          }
          case 18: { 
            this.valorUnidad += (this.cantOjaletes*1500);
            break; 
          }
          case 20: { 
            this.valorUnidad += (this.cantOjaletes*1400);
            break; 
          }
          default: { 
            this.valorUnidad += (this.cantOjaletes*2000);
             break; 
          } 
        } 
            
       }

       if(this.isCheckedDisenio == true){

        let valdisenio = ((this.anchoPend*this.altoPend*1*this.cantDisenios)/this.cantidadPend)

        this.valorUnidad += (valdisenio);
       }

       this.totalNeto = this.valorUnidad*this.cantidadPend;

       this.valorIva = (this.totalNeto*0.19);

       this.valorTotal = this.totalNeto + this.valorIva;

       const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })

       this.totalNeto2 = formatterPeso.format(this.totalNeto);
       this.valorUnidad2 = formatterPeso.format(this.valorUnidad);
       this.valorIva2 = formatterPeso.format(this.valorIva);
       this.valorTotal2 = formatterPeso.format(this.valorTotal);
       

       this.flagCotizar = true;
       
    }

  }

}
