import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  headers: any;

  constructor(private http: HttpClient) {

    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    if(window.localStorage.getItem('token') != null){
      this.headers = this.headers.append('Authorization',window.localStorage.getItem('token'));
    }

   }

  registerUser(data): Observable<any> {
    return this.http.post(environment.apiUrl+'api/register/', data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  loginUser(data): Observable<any> {
    return this.http.post(environment.apiUrl+'api/login/', data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getUser(data): Observable<any> {
    if(data == null){

      return this.http.get(environment.apiUrl+'api/user/', { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );

    }else{
      let headers2 = new HttpHeaders();
      headers2 = headers2.append('Content-Type', 'application/json');
      headers2 = headers2.append('Authorization',data.token);
      return this.http.get(environment.apiUrl+'api/user/', { headers:  headers2})
      .pipe(
        map((response: any) => {
          return response;
        })
      );
    }
    
  }

  logoutUser(data): Observable<any> {
    return this.http.post(environment.apiUrl+'api/logout/', data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
